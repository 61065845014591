import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Helmet } from "react-helmet";
import ClientTestimonials from "../components/client-testimonials";
import ContentWithVideo from "../components/content-with-video";
import FaqSection from "../components/faq-section";
import HeroBanner from "../components/hero";
import ImageGrid from "../components/image-grid";
import Layout from "../components/layout";
import TreePlanting from "../components/tree-planting";
import TwoColumnContentWithBottomImage from "../components/two-column-content-with-bottom-image";
import TwoColumnImageContent from "../components/two-column-image-content";

const TreeInMillionPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "tree-in-a-million" }) {
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							sourceUrl
							mediaDetails {
								height
								width
							}
						}
					}
				}
				treeInMillion {
					millionBannerSection {
						bannerSectionHeading
						bannerSectionDescription
						bannerSectionBgImage {
							node {
								altText
								sourceUrl
							}
						}
					}
					millionContentWithVideo {
						contentWithVideoHeading
						textContent
						videoYoutube
						uploadVideo {
							node {
								altText
								sourceUrl
								mediaItemUrl
							}
						}
					}
					joinOurInitiative {
						joinOurInitiativeHeading
						joinOurInitiativeContent
						backgroundColor
						joinOurInitiativeSteps {
							joinOurInitiativeStepHeading
							joinOurInitiativeStepDescription
						}
					}
					treeFiveImageGrid {
						treeFiveImageGridHeading
						treeFiveImageGridDescription
						backgroundColor
						treeFiveImageGridButton {
							title
							target
							url
						}
						treeFiveImageGridGallery {
							nodes {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					treePlantingSection {
						treePlantingSectionHeading
						treePlantingSectionSubHeading
						privatePlantingEventsHeading
						privatePlantingEventsDescription
						backgroundColor
						privatePlantingEvents {
							privatePlantingEventHeading
							privatePlantingEventDescription
							privatePlantingEventSubHeading
							privatePlantingEventPrice
							privatePlantingEventButton {
								title
								target
								url
							}
						}
						sponsorTreeHeading
						sponsorTreeDescription
						sponsorTreeCards {
							sponsorTreeCardHeading
							sponsorTreeCardDescription
							sponsorTreeCardPrice
							sponsorTreeCardIcon {
								node {
									altText
									sourceUrl
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: WEBP
												quality: 80
												transformOptions: { cropFocus: CENTER, fit: COVER }
												layout: CONSTRAINED
												placeholder: BLURRED
												width: 32
												height: 32
											)
										}
									}
								}
							}
							sponsorTreeCardButton {
								title
								target
								url
							}
						}
					}
					faqsSection {
						faqsSectionHeading
						faqsSectionDescription
						faqsSectionContent {
							answerContent
							questionText
						}
						faqsSectionCtaHeading
						faqsSectionCtaDescription
						faqsSectionCtaButton {
							title
							target
							url
						}
					}
					millionTestimonials {
						testimonialsSectionBgColor
						selectTestimonials {
							nodes {
								... on WpReview {
									title
									date(formatString: "DD MMMM, YYYY")
									reviewPostFields {
										reviewAuthor
										reviewAuthorDesignation
										reviewAuthorCompanyPicture {
											node {
												altText
												sourceUrl
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: WEBP
															quality: 80
															transformOptions: {
																cropFocus: CENTER
																fit: COVER
															}
															layout: CONSTRAINED
															placeholder: BLURRED
															width: 80
														)
													}
												}
											}
										}
										reviewQuote
										reviewStarSelect
										reviewAuthorProfilePicture {
											node {
												altText
												sourceUrl
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: WEBP
															quality: 80
															transformOptions: {
																cropFocus: CENTER
																fit: COVER
															}
															layout: CONSTRAINED
															placeholder: BLURRED
															width: 56
														)
													}
												}
											}
										}
									}
									terms {
										nodes {
											taxonomyName
											name
										}
									}
								}
							}
						}
					}
					millionTwoColumnImageContent {
						twoColumnHeading
						twoColumnContent
						twoColumnImageBackgroundColor
						twoColumnCta1 {
							title
							target
							url
						}
						twoColumnCta2 {
							title
							target
							url
						}
						twoColumnImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { seoFields, treeInMillion },
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Tree In A Million",
				item: {
					url: `${siteUrl}/tree-in-a-million`,
					id: `${siteUrl}/tree-in-a-million`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/tree-in-a-million`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.mediaDetails.width}`,
							height: `${seoFields?.image?.node.mediaDetails.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<HeroBanner
					heading={treeInMillion?.millionBannerSection.bannerSectionHeading}
					content={treeInMillion?.millionBannerSection.bannerSectionDescription}
					bgImage={
						treeInMillion?.millionBannerSection.bannerSectionBgImage?.node
							?.sourceUrl
					}
					showUnderline={false}
					textAlign={"center"}
					columns={7}
				/>
				<ContentWithVideo
					heading={
						treeInMillion?.millionContentWithVideo.contentWithVideoHeading
					}
					content={treeInMillion?.millionContentWithVideo.textContent}
					video={treeInMillion?.millionContentWithVideo.uploadVideo?.node}
					videoUrl={treeInMillion?.millionContentWithVideo?.videoYoutube}
				/>
				<TwoColumnContentWithBottomImage
					heading={treeInMillion?.joinOurInitiative.joinOurInitiativeHeading}
					content={treeInMillion?.joinOurInitiative.joinOurInitiativeContent}
					bgColor={treeInMillion?.joinOurInitiative.backgroundColor}
					steps={treeInMillion?.joinOurInitiative.joinOurInitiativeSteps}
					textColor="#fff"
					border="0px"
					showBgImage={false}
				/>
				<ImageGrid
					heading={treeInMillion?.treeFiveImageGrid.treeFiveImageGridHeading}
					content={
						treeInMillion?.treeFiveImageGrid.treeFiveImageGridDescription
					}
					btnCta={treeInMillion?.treeFiveImageGrid.treeFiveImageGridButton}
					bgColor={treeInMillion?.treeFiveImageGrid.backgroundColor}
					bgImage={
						treeInMillion?.treeFiveImageGrid.backgroundImage?.node.sourceUrl
					}
					galleryItems={
						treeInMillion?.treeFiveImageGrid.treeFiveImageGridGallery?.nodes
					}
					textColor="#252525"
				/>
				<TreePlanting
					heading={
						treeInMillion?.treePlantingSection.treePlantingSectionHeading
					}
					tagline={
						treeInMillion?.treePlantingSection.treePlantingSectionSubHeading
					}
					plantingHeading={
						treeInMillion?.treePlantingSection.privatePlantingEventsHeading
					}
					plantingDesc={
						treeInMillion?.treePlantingSection.privatePlantingEventsDescription
					}
					plantingEvents={
						treeInMillion?.treePlantingSection.privatePlantingEvents
					}
					sponsorHeading={treeInMillion?.treePlantingSection.sponsorTreeHeading}
					sponsorDesc={
						treeInMillion?.treePlantingSection.sponsorTreeDescription
					}
					treeCards={treeInMillion?.treePlantingSection.sponsorTreeCards}
					backgroundColor={treeInMillion?.treePlantingSection.backgroundColor}
				/>
				<FaqSection
					heading={treeInMillion?.faqsSection.faqsSectionHeading}
					content={treeInMillion?.faqsSection.faqsSectionDescription}
					faqData={treeInMillion?.faqsSection.faqsSectionContent}
					ctaHeading={treeInMillion?.faqsSection.faqsSectionCtaHeading}
					ctaSubheading={treeInMillion?.faqsSection.faqsSectionCtaDescription}
					ctaBtn={treeInMillion?.faqsSection.faqsSectionCtaButton}
				/>
				<ClientTestimonials
					testimonials={
						treeInMillion?.millionTestimonials.selectTestimonials?.nodes
					}
					bgColor={
						treeInMillion?.millionTestimonials.testimonialsSectionBgColor
					}
					textColor="#fff"
					starIconColor="#fff"
					wrapperClass="white-arrow"
				/>
				<TwoColumnImageContent
					heading={treeInMillion?.millionTwoColumnImageContent.twoColumnHeading}
					content={treeInMillion?.millionTwoColumnImageContent.twoColumnContent}
					btnCta1={treeInMillion?.millionTwoColumnImageContent.twoColumnCta1}
					btnCta2={treeInMillion?.millionTwoColumnImageContent.twoColumnCta2}
					image={
						treeInMillion?.millionTwoColumnImageContent.twoColumnImage.node
					}
					textColor={"#000"}
				/>
			</Layout>
		</>
	);
};

export default TreeInMillionPage;
