import React, { useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import LazyLoad from "react-lazyload";
import ReactPlayer from "react-player";
import VideoIcon from "../images/video.svg";

const CtaBanner = ({ heading, content, video, videoUrl }) => {
	const [showPlayBtn, setShowPlayBtn] = useState(true);

	const vidRef = useRef(null);
	const handlePlayVideo = () => {
		vidRef.current.play();
		setShowPlayBtn(!showPlayBtn);
	};

	return (
		<section className="py-8">
			<Container>
				<h2 className="my-0 text-light-black">{heading}</h2>
				<div
					className="text-light-black py-4"
					dangerouslySetInnerHTML={{ __html: content }}
				></div>
				{video && !videoUrl && (
					<LazyLoad height={200} offset={100}>
						<div className="position-relative">
							<video
								ref={vidRef}
								width="100%"
								controls
								height="auto"
								className="rounded-5 object-fit-cover"
								style={{ maxHeight: "640px" }}
							>
								<source src={videoUrl || video.mediaItemUrl} type="video/mp4" />
							</video>

							{showPlayBtn && (
								<VideoIcon
									onClick={handlePlayVideo}
									className="position-absolute mx-auto h-100 start-0 end-0 top-0 bottom-0"
								/>
							)}
						</div>
					</LazyLoad>
				)}
				{videoUrl && (
					<LazyLoad height={200} offset={100}>
						<div
							style={{ overflow: "hidden" }}
							className="rounded-5 object-fit-cover video-height-new "
						>
							<ReactPlayer width="100%" height="100%" controls url={videoUrl} />
						</div>
					</LazyLoad>
				)}
			</Container>
		</section>
	);
};

export default CtaBanner;
